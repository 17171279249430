export const SpIndexFloor = () => import('../..\\components\\spIndexFloor.vue' /* webpackChunkName: "components/sp-index-floor" */).then(c => wrapFunctional(c.default || c))
export const SpIndexFloorAside = () => import('../..\\components\\spIndexFloorAside.vue' /* webpackChunkName: "components/sp-index-floor-aside" */).then(c => wrapFunctional(c.default || c))
export const BackTop = () => import('../..\\components\\BackTop\\index.vue' /* webpackChunkName: "components/back-top" */).then(c => wrapFunctional(c.default || c))
export const CommonSpAddressForm = () => import('../..\\components\\common\\sp-address-form.vue' /* webpackChunkName: "components/common-sp-address-form" */).then(c => wrapFunctional(c.default || c))
export const CommonSpAddressList = () => import('../..\\components\\common\\sp-address-list.vue' /* webpackChunkName: "components/common-sp-address-list" */).then(c => wrapFunctional(c.default || c))
export const CommonSpCommentItem = () => import('../..\\components\\common\\sp-comment-item.vue' /* webpackChunkName: "components/common-sp-comment-item" */).then(c => wrapFunctional(c.default || c))
export const CommonSpCommentList = () => import('../..\\components\\common\\sp-comment-list.vue' /* webpackChunkName: "components/common-sp-comment-list" */).then(c => wrapFunctional(c.default || c))
export const CommonSpCopyright = () => import('../..\\components\\common\\sp-copyright.vue' /* webpackChunkName: "components/common-sp-copyright" */).then(c => wrapFunctional(c.default || c))
export const CommonSpCouponList = () => import('../..\\components\\common\\sp-coupon-list.vue' /* webpackChunkName: "components/common-sp-coupon-list" */).then(c => wrapFunctional(c.default || c))
export const CommonSpExpressList = () => import('../..\\components\\common\\sp-express-list.vue' /* webpackChunkName: "components/common-sp-express-list" */).then(c => wrapFunctional(c.default || c))
export const CommonSpFooter = () => import('../..\\components\\common\\sp-footer.vue' /* webpackChunkName: "components/common-sp-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonSpGoodBig = () => import('../..\\components\\common\\sp-good-big.vue' /* webpackChunkName: "components/common-sp-good-big" */).then(c => wrapFunctional(c.default || c))
export const CommonSpGoodSmall = () => import('../..\\components\\common\\sp-good-small.vue' /* webpackChunkName: "components/common-sp-good-small" */).then(c => wrapFunctional(c.default || c))
export const CommonSpGuessRow = () => import('../..\\components\\common\\sp-guess-row.vue' /* webpackChunkName: "components/common-sp-guess-row" */).then(c => wrapFunctional(c.default || c))
export const CommonSpHeader = () => import('../..\\components\\common\\sp-header.vue' /* webpackChunkName: "components/common-sp-header" */).then(c => wrapFunctional(c.default || c))
export const CommonSpIdcardList = () => import('../..\\components\\common\\sp-idcard-list.vue' /* webpackChunkName: "components/common-sp-idcard-list" */).then(c => wrapFunctional(c.default || c))
export const CommonSpOrderItem = () => import('../..\\components\\common\\sp-order-item.vue' /* webpackChunkName: "components/common-sp-order-item" */).then(c => wrapFunctional(c.default || c))
export const CommonSpPayList = () => import('../..\\components\\common\\sp-pay-list.vue' /* webpackChunkName: "components/common-sp-pay-list" */).then(c => wrapFunctional(c.default || c))
export const CommonSpRealForm = () => import('../..\\components\\common\\sp-real-form.vue' /* webpackChunkName: "components/common-sp-real-form" */).then(c => wrapFunctional(c.default || c))
export const CommonSpRealList = () => import('../..\\components\\common\\sp-real-list.vue' /* webpackChunkName: "components/common-sp-real-list" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../..\\components\\Loading\\index.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const SvgIcon = () => import('../..\\components\\svgIcon\\index.vue' /* webpackChunkName: "components/svg-icon" */).then(c => wrapFunctional(c.default || c))
export const VideoNuxt = () => import('../..\\components\\VideoNuxt\\index.vue' /* webpackChunkName: "components/video-nuxt" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
