export default {

  // 请求超时时间 默认30s
  TIMEOUT: 30 * 1000,
  // message notify 的默认显示时间
  DURATION: 2000,
  // cookie 前缀
  COOKIE_PREFIX: 'portal_',
  // code 白名单
  WHITE_CODE_LIST: [
    {
      code: 1,
      msg: 'success'
    }
  ],
  // token 错误
  LOGIN_ERROR_CODE: [
    {
      code: 5004,
      msg: '无效token'
    }
  ],
  REQUEST_BASE_URL: '',
  DOMAIN: '.shadowcreator.com',

  // 开发环境接口域名
  ENV_PREFIX: 'dev',
  // szssl1.sixking.com/shopro2
  DEV_API_URL: 'https://api.nkmax.cn/addons/shopro/',
  SSR_API_URL: 'https://api.nkmax.cn/addons/shopro/',
  FILE_API_URL: 'https://api.nkmax.cn/'

}
