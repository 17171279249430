import { render, staticRenderFns } from "./default.vue?vue&type=template&id=449c78c9&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BackTop: require('D:/公司项目/HBuilderX项目/NKMAX PC端/components/BackTop/index.vue').default})
