import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _44b210bc = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages/index" */))
const _30589bbc = () => interopDefault(import('..\\pages\\article\\index.vue' /* webpackChunkName: "pages/article/index" */))
const _0d790669 = () => interopDefault(import('..\\pages\\demo.vue' /* webpackChunkName: "pages/demo" */))
const _21653462 = () => interopDefault(import('..\\pages\\member\\index.vue' /* webpackChunkName: "pages/member/index" */))
const _aa14bc9e = () => interopDefault(import('..\\pages\\article\\detail.vue' /* webpackChunkName: "pages/article/detail" */))
const _05040f77 = () => interopDefault(import('..\\pages\\goods\\brand.vue' /* webpackChunkName: "pages/goods/brand" */))
const _a1291440 = () => interopDefault(import('..\\pages\\goods\\cart.vue' /* webpackChunkName: "pages/goods/cart" */))
const _46124afa = () => interopDefault(import('..\\pages\\goods\\collage.vue' /* webpackChunkName: "pages/goods/collage" */))
const _0e6da891 = () => interopDefault(import('..\\pages\\goods\\detail.vue' /* webpackChunkName: "pages/goods/detail" */))
const _8141da1e = () => interopDefault(import('..\\pages\\goods\\detail2.vue' /* webpackChunkName: "pages/goods/detail2" */))
const _01868744 = () => interopDefault(import('..\\pages\\goods\\list.vue' /* webpackChunkName: "pages/goods/list" */))
const _a37e6ab4 = () => interopDefault(import('..\\pages\\goods\\ranking.vue' /* webpackChunkName: "pages/goods/ranking" */))
const _9a52f5fc = () => interopDefault(import('..\\pages\\goods\\score.vue' /* webpackChunkName: "pages/goods/score" */))
const _21d97268 = () => interopDefault(import('..\\pages\\goods\\search.vue' /* webpackChunkName: "pages/goods/search" */))
const _9b917dc2 = () => interopDefault(import('..\\pages\\goods\\seckill.vue' /* webpackChunkName: "pages/goods/seckill" */))
const _cb1a0a94 = () => interopDefault(import('..\\pages\\goods\\shop.vue' /* webpackChunkName: "pages/goods/shop" */))
const _0c9aaef8 = () => interopDefault(import('..\\pages\\member\\address.vue' /* webpackChunkName: "pages/member/address" */))
const _0ce96dba = () => interopDefault(import('..\\pages\\member\\cancellation.vue' /* webpackChunkName: "pages/member/cancellation" */))
const _ad176984 = () => interopDefault(import('..\\pages\\member\\collection.vue' /* webpackChunkName: "pages/member/collection" */))
const _05af34c6 = () => interopDefault(import('..\\pages\\member\\coupon.vue' /* webpackChunkName: "pages/member/coupon" */))
const _478475b9 = () => interopDefault(import('..\\pages\\member\\evaluate.vue' /* webpackChunkName: "pages/member/evaluate" */))
const _f68cfb7a = () => interopDefault(import('..\\pages\\member\\exchange.vue' /* webpackChunkName: "pages/member/exchange" */))
const _58a34e28 = () => interopDefault(import('..\\pages\\member\\float.vue' /* webpackChunkName: "pages/member/float" */))
const _42b22ba2 = () => interopDefault(import('..\\pages\\member\\introduce.vue' /* webpackChunkName: "pages/member/introduce" */))
const _3a2ff4de = () => interopDefault(import('..\\pages\\member\\order.vue' /* webpackChunkName: "pages/member/order" */))
const _514eb2bb = () => interopDefault(import('..\\pages\\member\\password.vue' /* webpackChunkName: "pages/member/password" */))
const _2016c3e0 = () => interopDefault(import('..\\pages\\member\\personal.vue' /* webpackChunkName: "pages/member/personal" */))
const _79704dc0 = () => interopDefault(import('..\\pages\\order\\pay.vue' /* webpackChunkName: "pages/order/pay" */))
const _6b0e4e55 = () => interopDefault(import('..\\pages\\order\\result.vue' /* webpackChunkName: "pages/order/result" */))
const _ca8ca674 = () => interopDefault(import('..\\pages\\ucenter\\ucenter.vue' /* webpackChunkName: "pages/ucenter/ucenter" */))
const _020d929e = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\address.vue' /* webpackChunkName: "pages/ucenter/ucenter/address" */))
const _32703226 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\afterSale.vue' /* webpackChunkName: "pages/ucenter/ucenter/afterSale" */))
const _d70c33fc = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\bank.vue' /* webpackChunkName: "pages/ucenter/ucenter/bank" */))
const _d4ae43dc = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\capital.vue' /* webpackChunkName: "pages/ucenter/ucenter/capital" */))
const _15761a38 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\collection.vue' /* webpackChunkName: "pages/ucenter/ucenter/collection" */))
const _5cf290ee = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\comment.vue' /* webpackChunkName: "pages/ucenter/ucenter/comment" */))
const _88bcc128 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\coupon.vue' /* webpackChunkName: "pages/ucenter/ucenter/coupon" */))
const _f088492e = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\exchange.vue' /* webpackChunkName: "pages/ucenter/ucenter/exchange" */))
const _539677a9 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\footprint.vue' /* webpackChunkName: "pages/ucenter/ucenter/footprint" */))
const _0de4bb74 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\info.vue' /* webpackChunkName: "pages/ucenter/ucenter/info" */))
const _02c8deff = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\main.vue' /* webpackChunkName: "pages/ucenter/ucenter/main" */))
const _50929d78 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\order.vue' /* webpackChunkName: "pages/ucenter/ucenter/order" */))
const _54510be1 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\password.vue' /* webpackChunkName: "pages/ucenter/ucenter/password" */))
const _5ff68f38 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\real.vue' /* webpackChunkName: "pages/ucenter/ucenter/real" */))
const _15d81503 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\sign.vue' /* webpackChunkName: "pages/ucenter/ucenter/sign" */))
const _5db95b47 = () => interopDefault(import('..\\pages\\ucenter\\ucenter\\vip.vue' /* webpackChunkName: "pages/ucenter/ucenter/vip" */))
const _e98c7384 = () => interopDefault(import('..\\pages\\user\\forget.vue' /* webpackChunkName: "pages/user/forget" */))
const _2b796a28 = () => interopDefault(import('..\\pages\\user\\login.vue' /* webpackChunkName: "pages/user/login" */))
const _43cb843a = () => interopDefault(import('..\\pages\\user\\login2.vue' /* webpackChunkName: "pages/user/login2" */))
const _a15506ee = () => interopDefault(import('..\\pages\\user\\loginwx.vue' /* webpackChunkName: "pages/user/loginwx" */))
const _370cc498 = () => interopDefault(import('..\\pages\\user\\register.vue' /* webpackChunkName: "pages/user/register" */))
const _3036c9d0 = () => interopDefault(import('..\\pages\\goods\\components\\magnifier.vue' /* webpackChunkName: "pages/goods/components/magnifier" */))
const _44b2b9ca = () => interopDefault(import('..\\pages\\goods\\components\\sp-aside-boxes.vue' /* webpackChunkName: "pages/goods/components/sp-aside-boxes" */))
const _a5da0926 = () => interopDefault(import('..\\pages\\goods\\components\\sp-brand.vue' /* webpackChunkName: "pages/goods/components/sp-brand" */))
const _6a7ce85e = () => interopDefault(import('..\\pages\\goods\\components\\sp-bread.vue' /* webpackChunkName: "pages/goods/components/sp-bread" */))
const _99376b18 = () => interopDefault(import('..\\pages\\goods\\components\\sp-cart-guess.vue' /* webpackChunkName: "pages/goods/components/sp-cart-guess" */))
const _77cb2708 = () => interopDefault(import('..\\pages\\goods\\components\\sp-country.vue' /* webpackChunkName: "pages/goods/components/sp-country" */))
const _7f3e0b2a = () => interopDefault(import('..\\pages\\goods\\components\\sp-head-filter.vue' /* webpackChunkName: "pages/goods/components/sp-head-filter" */))
const _20d2d626 = () => interopDefault(import('..\\pages\\goods\\components\\sp-list-filter.vue' /* webpackChunkName: "pages/goods/components/sp-list-filter" */))
const _e376ddc8 = () => interopDefault(import('..\\pages\\goods\\components\\sp-ranking.vue' /* webpackChunkName: "pages/goods/components/sp-ranking" */))
const _2903344c = () => interopDefault(import('..\\pages\\member\\components\\sp-your-likes.vue' /* webpackChunkName: "pages/member/components/sp-your-likes" */))
const _166418d2 = () => interopDefault(import('..\\pages\\ucenter\\components\\sp-aside.vue' /* webpackChunkName: "pages/ucenter/components/sp-aside" */))
const _39f14996 = () => interopDefault(import('..\\pages\\ucenter\\components\\sp-menu.vue' /* webpackChunkName: "pages/ucenter/components/sp-menu" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ara",
    component: _44b210bc,
    name: "index___ara"
  }, {
    path: "/article",
    component: _30589bbc,
    name: "article___zh"
  }, {
    path: "/cht",
    component: _44b210bc,
    name: "index___cht"
  }, {
    path: "/demo",
    component: _0d790669,
    name: "demo___zh"
  }, {
    path: "/en",
    component: _44b210bc,
    name: "index___en"
  }, {
    path: "/fra",
    component: _44b210bc,
    name: "index___fra"
  }, {
    path: "/member",
    component: _21653462,
    name: "member___zh"
  }, {
    path: "/ara/article",
    component: _30589bbc,
    name: "article___ara"
  }, {
    path: "/ara/demo",
    component: _0d790669,
    name: "demo___ara"
  }, {
    path: "/ara/member",
    component: _21653462,
    name: "member___ara"
  }, {
    path: "/article/detail",
    component: _aa14bc9e,
    name: "article-detail___zh"
  }, {
    path: "/cht/article",
    component: _30589bbc,
    name: "article___cht"
  }, {
    path: "/cht/demo",
    component: _0d790669,
    name: "demo___cht"
  }, {
    path: "/cht/member",
    component: _21653462,
    name: "member___cht"
  }, {
    path: "/en/article",
    component: _30589bbc,
    name: "article___en"
  }, {
    path: "/en/demo",
    component: _0d790669,
    name: "demo___en"
  }, {
    path: "/en/member",
    component: _21653462,
    name: "member___en"
  }, {
    path: "/fra/article",
    component: _30589bbc,
    name: "article___fra"
  }, {
    path: "/fra/demo",
    component: _0d790669,
    name: "demo___fra"
  }, {
    path: "/fra/member",
    component: _21653462,
    name: "member___fra"
  }, {
    path: "/goods/brand",
    component: _05040f77,
    name: "goods-brand___zh"
  }, {
    path: "/goods/cart",
    component: _a1291440,
    name: "goods-cart___zh"
  }, {
    path: "/goods/collage",
    component: _46124afa,
    name: "goods-collage___zh"
  }, {
    path: "/goods/detail",
    component: _0e6da891,
    name: "goods-detail___zh"
  }, {
    path: "/goods/detail2",
    component: _8141da1e,
    name: "goods-detail2___zh"
  }, {
    path: "/goods/list",
    component: _01868744,
    name: "goods-list___zh"
  }, {
    path: "/goods/ranking",
    component: _a37e6ab4,
    name: "goods-ranking___zh"
  }, {
    path: "/goods/score",
    component: _9a52f5fc,
    name: "goods-score___zh"
  }, {
    path: "/goods/search",
    component: _21d97268,
    name: "goods-search___zh"
  }, {
    path: "/goods/seckill",
    component: _9b917dc2,
    name: "goods-seckill___zh"
  }, {
    path: "/goods/shop",
    component: _cb1a0a94,
    name: "goods-shop___zh"
  }, {
    path: "/member/address",
    component: _0c9aaef8,
    name: "member-address___zh"
  }, {
    path: "/member/cancellation",
    component: _0ce96dba,
    name: "member-cancellation___zh"
  }, {
    path: "/member/collection",
    component: _ad176984,
    name: "member-collection___zh"
  }, {
    path: "/member/coupon",
    component: _05af34c6,
    name: "member-coupon___zh"
  }, {
    path: "/member/evaluate",
    component: _478475b9,
    name: "member-evaluate___zh"
  }, {
    path: "/member/exchange",
    component: _f68cfb7a,
    name: "member-exchange___zh"
  }, {
    path: "/member/float",
    component: _58a34e28,
    name: "member-float___zh"
  }, {
    path: "/member/introduce",
    component: _42b22ba2,
    name: "member-introduce___zh"
  }, {
    path: "/member/order",
    component: _3a2ff4de,
    name: "member-order___zh"
  }, {
    path: "/member/password",
    component: _514eb2bb,
    name: "member-password___zh"
  }, {
    path: "/member/personal",
    component: _2016c3e0,
    name: "member-personal___zh"
  }, {
    path: "/order/pay",
    component: _79704dc0,
    name: "order-pay___zh"
  }, {
    path: "/order/result",
    component: _6b0e4e55,
    name: "order-result___zh"
  }, {
    path: "/ucenter/ucenter",
    component: _ca8ca674,
    name: "ucenter-ucenter___zh",
    children: [{
      path: "address",
      component: _020d929e,
      name: "ucenter-ucenter-address___zh"
    }, {
      path: "afterSale",
      component: _32703226,
      name: "ucenter-ucenter-afterSale___zh"
    }, {
      path: "bank",
      component: _d70c33fc,
      name: "ucenter-ucenter-bank___zh"
    }, {
      path: "capital",
      component: _d4ae43dc,
      name: "ucenter-ucenter-capital___zh"
    }, {
      path: "collection",
      component: _15761a38,
      name: "ucenter-ucenter-collection___zh"
    }, {
      path: "comment",
      component: _5cf290ee,
      name: "ucenter-ucenter-comment___zh"
    }, {
      path: "coupon",
      component: _88bcc128,
      name: "ucenter-ucenter-coupon___zh"
    }, {
      path: "exchange",
      component: _f088492e,
      name: "ucenter-ucenter-exchange___zh"
    }, {
      path: "footprint",
      component: _539677a9,
      name: "ucenter-ucenter-footprint___zh"
    }, {
      path: "info",
      component: _0de4bb74,
      name: "ucenter-ucenter-info___zh"
    }, {
      path: "main",
      component: _02c8deff,
      name: "ucenter-ucenter-main___zh"
    }, {
      path: "order",
      component: _50929d78,
      name: "ucenter-ucenter-order___zh"
    }, {
      path: "password",
      component: _54510be1,
      name: "ucenter-ucenter-password___zh"
    }, {
      path: "real",
      component: _5ff68f38,
      name: "ucenter-ucenter-real___zh"
    }, {
      path: "sign",
      component: _15d81503,
      name: "ucenter-ucenter-sign___zh"
    }, {
      path: "vip",
      component: _5db95b47,
      name: "ucenter-ucenter-vip___zh"
    }]
  }, {
    path: "/user/forget",
    component: _e98c7384,
    name: "user-forget___zh"
  }, {
    path: "/user/login",
    component: _2b796a28,
    name: "user-login___zh"
  }, {
    path: "/user/login2",
    component: _43cb843a,
    name: "user-login2___zh"
  }, {
    path: "/user/loginwx",
    component: _a15506ee,
    name: "user-loginwx___zh"
  }, {
    path: "/user/register",
    component: _370cc498,
    name: "user-register___zh"
  }, {
    path: "/ara/article/detail",
    component: _aa14bc9e,
    name: "article-detail___ara"
  }, {
    path: "/ara/goods/brand",
    component: _05040f77,
    name: "goods-brand___ara"
  }, {
    path: "/ara/goods/cart",
    component: _a1291440,
    name: "goods-cart___ara"
  }, {
    path: "/ara/goods/collage",
    component: _46124afa,
    name: "goods-collage___ara"
  }, {
    path: "/ara/goods/detail",
    component: _0e6da891,
    name: "goods-detail___ara"
  }, {
    path: "/ara/goods/detail2",
    component: _8141da1e,
    name: "goods-detail2___ara"
  }, {
    path: "/ara/goods/list",
    component: _01868744,
    name: "goods-list___ara"
  }, {
    path: "/ara/goods/ranking",
    component: _a37e6ab4,
    name: "goods-ranking___ara"
  }, {
    path: "/ara/goods/score",
    component: _9a52f5fc,
    name: "goods-score___ara"
  }, {
    path: "/ara/goods/search",
    component: _21d97268,
    name: "goods-search___ara"
  }, {
    path: "/ara/goods/seckill",
    component: _9b917dc2,
    name: "goods-seckill___ara"
  }, {
    path: "/ara/goods/shop",
    component: _cb1a0a94,
    name: "goods-shop___ara"
  }, {
    path: "/ara/member/address",
    component: _0c9aaef8,
    name: "member-address___ara"
  }, {
    path: "/ara/member/cancellation",
    component: _0ce96dba,
    name: "member-cancellation___ara"
  }, {
    path: "/ara/member/collection",
    component: _ad176984,
    name: "member-collection___ara"
  }, {
    path: "/ara/member/coupon",
    component: _05af34c6,
    name: "member-coupon___ara"
  }, {
    path: "/ara/member/evaluate",
    component: _478475b9,
    name: "member-evaluate___ara"
  }, {
    path: "/ara/member/exchange",
    component: _f68cfb7a,
    name: "member-exchange___ara"
  }, {
    path: "/ara/member/float",
    component: _58a34e28,
    name: "member-float___ara"
  }, {
    path: "/ara/member/introduce",
    component: _42b22ba2,
    name: "member-introduce___ara"
  }, {
    path: "/ara/member/order",
    component: _3a2ff4de,
    name: "member-order___ara"
  }, {
    path: "/ara/member/password",
    component: _514eb2bb,
    name: "member-password___ara"
  }, {
    path: "/ara/member/personal",
    component: _2016c3e0,
    name: "member-personal___ara"
  }, {
    path: "/ara/order/pay",
    component: _79704dc0,
    name: "order-pay___ara"
  }, {
    path: "/ara/order/result",
    component: _6b0e4e55,
    name: "order-result___ara"
  }, {
    path: "/ara/ucenter/ucenter",
    component: _ca8ca674,
    name: "ucenter-ucenter___ara",
    children: [{
      path: "address",
      component: _020d929e,
      name: "ucenter-ucenter-address___ara"
    }, {
      path: "afterSale",
      component: _32703226,
      name: "ucenter-ucenter-afterSale___ara"
    }, {
      path: "bank",
      component: _d70c33fc,
      name: "ucenter-ucenter-bank___ara"
    }, {
      path: "capital",
      component: _d4ae43dc,
      name: "ucenter-ucenter-capital___ara"
    }, {
      path: "collection",
      component: _15761a38,
      name: "ucenter-ucenter-collection___ara"
    }, {
      path: "comment",
      component: _5cf290ee,
      name: "ucenter-ucenter-comment___ara"
    }, {
      path: "coupon",
      component: _88bcc128,
      name: "ucenter-ucenter-coupon___ara"
    }, {
      path: "exchange",
      component: _f088492e,
      name: "ucenter-ucenter-exchange___ara"
    }, {
      path: "footprint",
      component: _539677a9,
      name: "ucenter-ucenter-footprint___ara"
    }, {
      path: "info",
      component: _0de4bb74,
      name: "ucenter-ucenter-info___ara"
    }, {
      path: "main",
      component: _02c8deff,
      name: "ucenter-ucenter-main___ara"
    }, {
      path: "order",
      component: _50929d78,
      name: "ucenter-ucenter-order___ara"
    }, {
      path: "password",
      component: _54510be1,
      name: "ucenter-ucenter-password___ara"
    }, {
      path: "real",
      component: _5ff68f38,
      name: "ucenter-ucenter-real___ara"
    }, {
      path: "sign",
      component: _15d81503,
      name: "ucenter-ucenter-sign___ara"
    }, {
      path: "vip",
      component: _5db95b47,
      name: "ucenter-ucenter-vip___ara"
    }]
  }, {
    path: "/ara/user/forget",
    component: _e98c7384,
    name: "user-forget___ara"
  }, {
    path: "/ara/user/login",
    component: _2b796a28,
    name: "user-login___ara"
  }, {
    path: "/ara/user/login2",
    component: _43cb843a,
    name: "user-login2___ara"
  }, {
    path: "/ara/user/loginwx",
    component: _a15506ee,
    name: "user-loginwx___ara"
  }, {
    path: "/ara/user/register",
    component: _370cc498,
    name: "user-register___ara"
  }, {
    path: "/cht/article/detail",
    component: _aa14bc9e,
    name: "article-detail___cht"
  }, {
    path: "/cht/goods/brand",
    component: _05040f77,
    name: "goods-brand___cht"
  }, {
    path: "/cht/goods/cart",
    component: _a1291440,
    name: "goods-cart___cht"
  }, {
    path: "/cht/goods/collage",
    component: _46124afa,
    name: "goods-collage___cht"
  }, {
    path: "/cht/goods/detail",
    component: _0e6da891,
    name: "goods-detail___cht"
  }, {
    path: "/cht/goods/detail2",
    component: _8141da1e,
    name: "goods-detail2___cht"
  }, {
    path: "/cht/goods/list",
    component: _01868744,
    name: "goods-list___cht"
  }, {
    path: "/cht/goods/ranking",
    component: _a37e6ab4,
    name: "goods-ranking___cht"
  }, {
    path: "/cht/goods/score",
    component: _9a52f5fc,
    name: "goods-score___cht"
  }, {
    path: "/cht/goods/search",
    component: _21d97268,
    name: "goods-search___cht"
  }, {
    path: "/cht/goods/seckill",
    component: _9b917dc2,
    name: "goods-seckill___cht"
  }, {
    path: "/cht/goods/shop",
    component: _cb1a0a94,
    name: "goods-shop___cht"
  }, {
    path: "/cht/member/address",
    component: _0c9aaef8,
    name: "member-address___cht"
  }, {
    path: "/cht/member/cancellation",
    component: _0ce96dba,
    name: "member-cancellation___cht"
  }, {
    path: "/cht/member/collection",
    component: _ad176984,
    name: "member-collection___cht"
  }, {
    path: "/cht/member/coupon",
    component: _05af34c6,
    name: "member-coupon___cht"
  }, {
    path: "/cht/member/evaluate",
    component: _478475b9,
    name: "member-evaluate___cht"
  }, {
    path: "/cht/member/exchange",
    component: _f68cfb7a,
    name: "member-exchange___cht"
  }, {
    path: "/cht/member/float",
    component: _58a34e28,
    name: "member-float___cht"
  }, {
    path: "/cht/member/introduce",
    component: _42b22ba2,
    name: "member-introduce___cht"
  }, {
    path: "/cht/member/order",
    component: _3a2ff4de,
    name: "member-order___cht"
  }, {
    path: "/cht/member/password",
    component: _514eb2bb,
    name: "member-password___cht"
  }, {
    path: "/cht/member/personal",
    component: _2016c3e0,
    name: "member-personal___cht"
  }, {
    path: "/cht/order/pay",
    component: _79704dc0,
    name: "order-pay___cht"
  }, {
    path: "/cht/order/result",
    component: _6b0e4e55,
    name: "order-result___cht"
  }, {
    path: "/cht/ucenter/ucenter",
    component: _ca8ca674,
    name: "ucenter-ucenter___cht",
    children: [{
      path: "address",
      component: _020d929e,
      name: "ucenter-ucenter-address___cht"
    }, {
      path: "afterSale",
      component: _32703226,
      name: "ucenter-ucenter-afterSale___cht"
    }, {
      path: "bank",
      component: _d70c33fc,
      name: "ucenter-ucenter-bank___cht"
    }, {
      path: "capital",
      component: _d4ae43dc,
      name: "ucenter-ucenter-capital___cht"
    }, {
      path: "collection",
      component: _15761a38,
      name: "ucenter-ucenter-collection___cht"
    }, {
      path: "comment",
      component: _5cf290ee,
      name: "ucenter-ucenter-comment___cht"
    }, {
      path: "coupon",
      component: _88bcc128,
      name: "ucenter-ucenter-coupon___cht"
    }, {
      path: "exchange",
      component: _f088492e,
      name: "ucenter-ucenter-exchange___cht"
    }, {
      path: "footprint",
      component: _539677a9,
      name: "ucenter-ucenter-footprint___cht"
    }, {
      path: "info",
      component: _0de4bb74,
      name: "ucenter-ucenter-info___cht"
    }, {
      path: "main",
      component: _02c8deff,
      name: "ucenter-ucenter-main___cht"
    }, {
      path: "order",
      component: _50929d78,
      name: "ucenter-ucenter-order___cht"
    }, {
      path: "password",
      component: _54510be1,
      name: "ucenter-ucenter-password___cht"
    }, {
      path: "real",
      component: _5ff68f38,
      name: "ucenter-ucenter-real___cht"
    }, {
      path: "sign",
      component: _15d81503,
      name: "ucenter-ucenter-sign___cht"
    }, {
      path: "vip",
      component: _5db95b47,
      name: "ucenter-ucenter-vip___cht"
    }]
  }, {
    path: "/cht/user/forget",
    component: _e98c7384,
    name: "user-forget___cht"
  }, {
    path: "/cht/user/login",
    component: _2b796a28,
    name: "user-login___cht"
  }, {
    path: "/cht/user/login2",
    component: _43cb843a,
    name: "user-login2___cht"
  }, {
    path: "/cht/user/loginwx",
    component: _a15506ee,
    name: "user-loginwx___cht"
  }, {
    path: "/cht/user/register",
    component: _370cc498,
    name: "user-register___cht"
  }, {
    path: "/en/article/detail",
    component: _aa14bc9e,
    name: "article-detail___en"
  }, {
    path: "/en/goods/brand",
    component: _05040f77,
    name: "goods-brand___en"
  }, {
    path: "/en/goods/cart",
    component: _a1291440,
    name: "goods-cart___en"
  }, {
    path: "/en/goods/collage",
    component: _46124afa,
    name: "goods-collage___en"
  }, {
    path: "/en/goods/detail",
    component: _0e6da891,
    name: "goods-detail___en"
  }, {
    path: "/en/goods/detail2",
    component: _8141da1e,
    name: "goods-detail2___en"
  }, {
    path: "/en/goods/list",
    component: _01868744,
    name: "goods-list___en"
  }, {
    path: "/en/goods/ranking",
    component: _a37e6ab4,
    name: "goods-ranking___en"
  }, {
    path: "/en/goods/score",
    component: _9a52f5fc,
    name: "goods-score___en"
  }, {
    path: "/en/goods/search",
    component: _21d97268,
    name: "goods-search___en"
  }, {
    path: "/en/goods/seckill",
    component: _9b917dc2,
    name: "goods-seckill___en"
  }, {
    path: "/en/goods/shop",
    component: _cb1a0a94,
    name: "goods-shop___en"
  }, {
    path: "/en/member/address",
    component: _0c9aaef8,
    name: "member-address___en"
  }, {
    path: "/en/member/cancellation",
    component: _0ce96dba,
    name: "member-cancellation___en"
  }, {
    path: "/en/member/collection",
    component: _ad176984,
    name: "member-collection___en"
  }, {
    path: "/en/member/coupon",
    component: _05af34c6,
    name: "member-coupon___en"
  }, {
    path: "/en/member/evaluate",
    component: _478475b9,
    name: "member-evaluate___en"
  }, {
    path: "/en/member/exchange",
    component: _f68cfb7a,
    name: "member-exchange___en"
  }, {
    path: "/en/member/float",
    component: _58a34e28,
    name: "member-float___en"
  }, {
    path: "/en/member/introduce",
    component: _42b22ba2,
    name: "member-introduce___en"
  }, {
    path: "/en/member/order",
    component: _3a2ff4de,
    name: "member-order___en"
  }, {
    path: "/en/member/password",
    component: _514eb2bb,
    name: "member-password___en"
  }, {
    path: "/en/member/personal",
    component: _2016c3e0,
    name: "member-personal___en"
  }, {
    path: "/en/order/pay",
    component: _79704dc0,
    name: "order-pay___en"
  }, {
    path: "/en/order/result",
    component: _6b0e4e55,
    name: "order-result___en"
  }, {
    path: "/en/ucenter/ucenter",
    component: _ca8ca674,
    name: "ucenter-ucenter___en",
    children: [{
      path: "address",
      component: _020d929e,
      name: "ucenter-ucenter-address___en"
    }, {
      path: "afterSale",
      component: _32703226,
      name: "ucenter-ucenter-afterSale___en"
    }, {
      path: "bank",
      component: _d70c33fc,
      name: "ucenter-ucenter-bank___en"
    }, {
      path: "capital",
      component: _d4ae43dc,
      name: "ucenter-ucenter-capital___en"
    }, {
      path: "collection",
      component: _15761a38,
      name: "ucenter-ucenter-collection___en"
    }, {
      path: "comment",
      component: _5cf290ee,
      name: "ucenter-ucenter-comment___en"
    }, {
      path: "coupon",
      component: _88bcc128,
      name: "ucenter-ucenter-coupon___en"
    }, {
      path: "exchange",
      component: _f088492e,
      name: "ucenter-ucenter-exchange___en"
    }, {
      path: "footprint",
      component: _539677a9,
      name: "ucenter-ucenter-footprint___en"
    }, {
      path: "info",
      component: _0de4bb74,
      name: "ucenter-ucenter-info___en"
    }, {
      path: "main",
      component: _02c8deff,
      name: "ucenter-ucenter-main___en"
    }, {
      path: "order",
      component: _50929d78,
      name: "ucenter-ucenter-order___en"
    }, {
      path: "password",
      component: _54510be1,
      name: "ucenter-ucenter-password___en"
    }, {
      path: "real",
      component: _5ff68f38,
      name: "ucenter-ucenter-real___en"
    }, {
      path: "sign",
      component: _15d81503,
      name: "ucenter-ucenter-sign___en"
    }, {
      path: "vip",
      component: _5db95b47,
      name: "ucenter-ucenter-vip___en"
    }]
  }, {
    path: "/en/user/forget",
    component: _e98c7384,
    name: "user-forget___en"
  }, {
    path: "/en/user/login",
    component: _2b796a28,
    name: "user-login___en"
  }, {
    path: "/en/user/login2",
    component: _43cb843a,
    name: "user-login2___en"
  }, {
    path: "/en/user/loginwx",
    component: _a15506ee,
    name: "user-loginwx___en"
  }, {
    path: "/en/user/register",
    component: _370cc498,
    name: "user-register___en"
  }, {
    path: "/fra/article/detail",
    component: _aa14bc9e,
    name: "article-detail___fra"
  }, {
    path: "/fra/goods/brand",
    component: _05040f77,
    name: "goods-brand___fra"
  }, {
    path: "/fra/goods/cart",
    component: _a1291440,
    name: "goods-cart___fra"
  }, {
    path: "/fra/goods/collage",
    component: _46124afa,
    name: "goods-collage___fra"
  }, {
    path: "/fra/goods/detail",
    component: _0e6da891,
    name: "goods-detail___fra"
  }, {
    path: "/fra/goods/detail2",
    component: _8141da1e,
    name: "goods-detail2___fra"
  }, {
    path: "/fra/goods/list",
    component: _01868744,
    name: "goods-list___fra"
  }, {
    path: "/fra/goods/ranking",
    component: _a37e6ab4,
    name: "goods-ranking___fra"
  }, {
    path: "/fra/goods/score",
    component: _9a52f5fc,
    name: "goods-score___fra"
  }, {
    path: "/fra/goods/search",
    component: _21d97268,
    name: "goods-search___fra"
  }, {
    path: "/fra/goods/seckill",
    component: _9b917dc2,
    name: "goods-seckill___fra"
  }, {
    path: "/fra/goods/shop",
    component: _cb1a0a94,
    name: "goods-shop___fra"
  }, {
    path: "/fra/member/address",
    component: _0c9aaef8,
    name: "member-address___fra"
  }, {
    path: "/fra/member/cancellation",
    component: _0ce96dba,
    name: "member-cancellation___fra"
  }, {
    path: "/fra/member/collection",
    component: _ad176984,
    name: "member-collection___fra"
  }, {
    path: "/fra/member/coupon",
    component: _05af34c6,
    name: "member-coupon___fra"
  }, {
    path: "/fra/member/evaluate",
    component: _478475b9,
    name: "member-evaluate___fra"
  }, {
    path: "/fra/member/exchange",
    component: _f68cfb7a,
    name: "member-exchange___fra"
  }, {
    path: "/fra/member/float",
    component: _58a34e28,
    name: "member-float___fra"
  }, {
    path: "/fra/member/introduce",
    component: _42b22ba2,
    name: "member-introduce___fra"
  }, {
    path: "/fra/member/order",
    component: _3a2ff4de,
    name: "member-order___fra"
  }, {
    path: "/fra/member/password",
    component: _514eb2bb,
    name: "member-password___fra"
  }, {
    path: "/fra/member/personal",
    component: _2016c3e0,
    name: "member-personal___fra"
  }, {
    path: "/fra/order/pay",
    component: _79704dc0,
    name: "order-pay___fra"
  }, {
    path: "/fra/order/result",
    component: _6b0e4e55,
    name: "order-result___fra"
  }, {
    path: "/fra/ucenter/ucenter",
    component: _ca8ca674,
    name: "ucenter-ucenter___fra",
    children: [{
      path: "address",
      component: _020d929e,
      name: "ucenter-ucenter-address___fra"
    }, {
      path: "afterSale",
      component: _32703226,
      name: "ucenter-ucenter-afterSale___fra"
    }, {
      path: "bank",
      component: _d70c33fc,
      name: "ucenter-ucenter-bank___fra"
    }, {
      path: "capital",
      component: _d4ae43dc,
      name: "ucenter-ucenter-capital___fra"
    }, {
      path: "collection",
      component: _15761a38,
      name: "ucenter-ucenter-collection___fra"
    }, {
      path: "comment",
      component: _5cf290ee,
      name: "ucenter-ucenter-comment___fra"
    }, {
      path: "coupon",
      component: _88bcc128,
      name: "ucenter-ucenter-coupon___fra"
    }, {
      path: "exchange",
      component: _f088492e,
      name: "ucenter-ucenter-exchange___fra"
    }, {
      path: "footprint",
      component: _539677a9,
      name: "ucenter-ucenter-footprint___fra"
    }, {
      path: "info",
      component: _0de4bb74,
      name: "ucenter-ucenter-info___fra"
    }, {
      path: "main",
      component: _02c8deff,
      name: "ucenter-ucenter-main___fra"
    }, {
      path: "order",
      component: _50929d78,
      name: "ucenter-ucenter-order___fra"
    }, {
      path: "password",
      component: _54510be1,
      name: "ucenter-ucenter-password___fra"
    }, {
      path: "real",
      component: _5ff68f38,
      name: "ucenter-ucenter-real___fra"
    }, {
      path: "sign",
      component: _15d81503,
      name: "ucenter-ucenter-sign___fra"
    }, {
      path: "vip",
      component: _5db95b47,
      name: "ucenter-ucenter-vip___fra"
    }]
  }, {
    path: "/fra/user/forget",
    component: _e98c7384,
    name: "user-forget___fra"
  }, {
    path: "/fra/user/login",
    component: _2b796a28,
    name: "user-login___fra"
  }, {
    path: "/fra/user/login2",
    component: _43cb843a,
    name: "user-login2___fra"
  }, {
    path: "/fra/user/loginwx",
    component: _a15506ee,
    name: "user-loginwx___fra"
  }, {
    path: "/fra/user/register",
    component: _370cc498,
    name: "user-register___fra"
  }, {
    path: "/goods/components/magnifier",
    component: _3036c9d0,
    name: "goods-components-magnifier___zh"
  }, {
    path: "/goods/components/sp-aside-boxes",
    component: _44b2b9ca,
    name: "goods-components-sp-aside-boxes___zh"
  }, {
    path: "/goods/components/sp-brand",
    component: _a5da0926,
    name: "goods-components-sp-brand___zh"
  }, {
    path: "/goods/components/sp-bread",
    component: _6a7ce85e,
    name: "goods-components-sp-bread___zh"
  }, {
    path: "/goods/components/sp-cart-guess",
    component: _99376b18,
    name: "goods-components-sp-cart-guess___zh"
  }, {
    path: "/goods/components/sp-country",
    component: _77cb2708,
    name: "goods-components-sp-country___zh"
  }, {
    path: "/goods/components/sp-head-filter",
    component: _7f3e0b2a,
    name: "goods-components-sp-head-filter___zh"
  }, {
    path: "/goods/components/sp-list-filter",
    component: _20d2d626,
    name: "goods-components-sp-list-filter___zh"
  }, {
    path: "/goods/components/sp-ranking",
    component: _e376ddc8,
    name: "goods-components-sp-ranking___zh"
  }, {
    path: "/member/components/sp-your-likes",
    component: _2903344c,
    name: "member-components-sp-your-likes___zh"
  }, {
    path: "/ucenter/components/sp-aside",
    component: _166418d2,
    name: "ucenter-components-sp-aside___zh"
  }, {
    path: "/ucenter/components/sp-menu",
    component: _39f14996,
    name: "ucenter-components-sp-menu___zh"
  }, {
    path: "/ara/goods/components/magnifier",
    component: _3036c9d0,
    name: "goods-components-magnifier___ara"
  }, {
    path: "/ara/goods/components/sp-aside-boxes",
    component: _44b2b9ca,
    name: "goods-components-sp-aside-boxes___ara"
  }, {
    path: "/ara/goods/components/sp-brand",
    component: _a5da0926,
    name: "goods-components-sp-brand___ara"
  }, {
    path: "/ara/goods/components/sp-bread",
    component: _6a7ce85e,
    name: "goods-components-sp-bread___ara"
  }, {
    path: "/ara/goods/components/sp-cart-guess",
    component: _99376b18,
    name: "goods-components-sp-cart-guess___ara"
  }, {
    path: "/ara/goods/components/sp-country",
    component: _77cb2708,
    name: "goods-components-sp-country___ara"
  }, {
    path: "/ara/goods/components/sp-head-filter",
    component: _7f3e0b2a,
    name: "goods-components-sp-head-filter___ara"
  }, {
    path: "/ara/goods/components/sp-list-filter",
    component: _20d2d626,
    name: "goods-components-sp-list-filter___ara"
  }, {
    path: "/ara/goods/components/sp-ranking",
    component: _e376ddc8,
    name: "goods-components-sp-ranking___ara"
  }, {
    path: "/ara/member/components/sp-your-likes",
    component: _2903344c,
    name: "member-components-sp-your-likes___ara"
  }, {
    path: "/ara/ucenter/components/sp-aside",
    component: _166418d2,
    name: "ucenter-components-sp-aside___ara"
  }, {
    path: "/ara/ucenter/components/sp-menu",
    component: _39f14996,
    name: "ucenter-components-sp-menu___ara"
  }, {
    path: "/cht/goods/components/magnifier",
    component: _3036c9d0,
    name: "goods-components-magnifier___cht"
  }, {
    path: "/cht/goods/components/sp-aside-boxes",
    component: _44b2b9ca,
    name: "goods-components-sp-aside-boxes___cht"
  }, {
    path: "/cht/goods/components/sp-brand",
    component: _a5da0926,
    name: "goods-components-sp-brand___cht"
  }, {
    path: "/cht/goods/components/sp-bread",
    component: _6a7ce85e,
    name: "goods-components-sp-bread___cht"
  }, {
    path: "/cht/goods/components/sp-cart-guess",
    component: _99376b18,
    name: "goods-components-sp-cart-guess___cht"
  }, {
    path: "/cht/goods/components/sp-country",
    component: _77cb2708,
    name: "goods-components-sp-country___cht"
  }, {
    path: "/cht/goods/components/sp-head-filter",
    component: _7f3e0b2a,
    name: "goods-components-sp-head-filter___cht"
  }, {
    path: "/cht/goods/components/sp-list-filter",
    component: _20d2d626,
    name: "goods-components-sp-list-filter___cht"
  }, {
    path: "/cht/goods/components/sp-ranking",
    component: _e376ddc8,
    name: "goods-components-sp-ranking___cht"
  }, {
    path: "/cht/member/components/sp-your-likes",
    component: _2903344c,
    name: "member-components-sp-your-likes___cht"
  }, {
    path: "/cht/ucenter/components/sp-aside",
    component: _166418d2,
    name: "ucenter-components-sp-aside___cht"
  }, {
    path: "/cht/ucenter/components/sp-menu",
    component: _39f14996,
    name: "ucenter-components-sp-menu___cht"
  }, {
    path: "/en/goods/components/magnifier",
    component: _3036c9d0,
    name: "goods-components-magnifier___en"
  }, {
    path: "/en/goods/components/sp-aside-boxes",
    component: _44b2b9ca,
    name: "goods-components-sp-aside-boxes___en"
  }, {
    path: "/en/goods/components/sp-brand",
    component: _a5da0926,
    name: "goods-components-sp-brand___en"
  }, {
    path: "/en/goods/components/sp-bread",
    component: _6a7ce85e,
    name: "goods-components-sp-bread___en"
  }, {
    path: "/en/goods/components/sp-cart-guess",
    component: _99376b18,
    name: "goods-components-sp-cart-guess___en"
  }, {
    path: "/en/goods/components/sp-country",
    component: _77cb2708,
    name: "goods-components-sp-country___en"
  }, {
    path: "/en/goods/components/sp-head-filter",
    component: _7f3e0b2a,
    name: "goods-components-sp-head-filter___en"
  }, {
    path: "/en/goods/components/sp-list-filter",
    component: _20d2d626,
    name: "goods-components-sp-list-filter___en"
  }, {
    path: "/en/goods/components/sp-ranking",
    component: _e376ddc8,
    name: "goods-components-sp-ranking___en"
  }, {
    path: "/en/member/components/sp-your-likes",
    component: _2903344c,
    name: "member-components-sp-your-likes___en"
  }, {
    path: "/en/ucenter/components/sp-aside",
    component: _166418d2,
    name: "ucenter-components-sp-aside___en"
  }, {
    path: "/en/ucenter/components/sp-menu",
    component: _39f14996,
    name: "ucenter-components-sp-menu___en"
  }, {
    path: "/fra/goods/components/magnifier",
    component: _3036c9d0,
    name: "goods-components-magnifier___fra"
  }, {
    path: "/fra/goods/components/sp-aside-boxes",
    component: _44b2b9ca,
    name: "goods-components-sp-aside-boxes___fra"
  }, {
    path: "/fra/goods/components/sp-brand",
    component: _a5da0926,
    name: "goods-components-sp-brand___fra"
  }, {
    path: "/fra/goods/components/sp-bread",
    component: _6a7ce85e,
    name: "goods-components-sp-bread___fra"
  }, {
    path: "/fra/goods/components/sp-cart-guess",
    component: _99376b18,
    name: "goods-components-sp-cart-guess___fra"
  }, {
    path: "/fra/goods/components/sp-country",
    component: _77cb2708,
    name: "goods-components-sp-country___fra"
  }, {
    path: "/fra/goods/components/sp-head-filter",
    component: _7f3e0b2a,
    name: "goods-components-sp-head-filter___fra"
  }, {
    path: "/fra/goods/components/sp-list-filter",
    component: _20d2d626,
    name: "goods-components-sp-list-filter___fra"
  }, {
    path: "/fra/goods/components/sp-ranking",
    component: _e376ddc8,
    name: "goods-components-sp-ranking___fra"
  }, {
    path: "/fra/member/components/sp-your-likes",
    component: _2903344c,
    name: "member-components-sp-your-likes___fra"
  }, {
    path: "/fra/ucenter/components/sp-aside",
    component: _166418d2,
    name: "ucenter-components-sp-aside___fra"
  }, {
    path: "/fra/ucenter/components/sp-menu",
    component: _39f14996,
    name: "ucenter-components-sp-menu___fra"
  }, {
    path: "/",
    component: _44b210bc,
    name: "index___zh"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
